










import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  data() {
    return {
      shown: false,
    };
  },
  extraOptions: {
    defaultClass: "passwordInput",
  },
});
